import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';
import { getUser, getUsers, updateUser, createUser } from '@/api/user';

const initialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            role: {
                id: {
                    $containsi: '',
                },
            },
            parkingLots: {
                id: {
                    $containsi: '',
                },
            },
            $or: [
                {
                    name: {
                        $containsi: '',
                    },
                },
                {
                    email: {
                        $containsi: '',
                    },
                },
                {
                    parkingLots: {
                        name: {
                            $containsi: '',
                        },
                    },
                },
            ],
        },
        populate: ['picture', 'role', 'parkingLots'],
    };
};
export default {
    namespaced: true,
    state: {
        users: [],
        query: initialQuery(),
    },
    getters: {
        getUserList(state, getters) {
            return state.users;
        },
        getUserQuery(state, getters) {
            return state.query;
        },
        getUser(state, getters) {
            return state.user;
        },
    },
    mutations: {
        setUserList(state, users) {
            state.users = users;
        },
        setUserQuery(state, query) {
            state.query = query;
        },
        resetUserQuery(state) {
            state.query = initialQuery();
        },
        setUser(state, userData) {
            state.userData = userData;
        },
        setUserSearch(state, search) {
            state.query.filters.$or[0].name.$containsi = search;
            state.query.filters.$or[1].email.$containsi = search;
            state.query.filters.$or[2].parkingLots.name.$containsi = search;
        },
    },
    actions: {
        /*     fetchUsers(ctx) {
            return new Promise((resolve, reject) => {
                getUsers(ctx.getters.getUserQuery)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }, */
        fetchUsers(ctx) {
            let _query = _.cloneDeep(ctx.state.query);
            //delete _query.filters.country.id.$containsi;

            /*  if (!_query.filters.district.id.$containsi) {
                delete _query.filters.district;
            }
            if (!_query.filters.county.id.$containsi) {
                delete _query.filters.county;
            }
            if (!_query.filters.parish.id.$containsi) {
                delete _query.filters.parish;
            } */
            if (!_query.filters.parkingLots.id.$containsi) {
                delete _query.filters.parkingLots;
            }
            if (!_query.filters.role.id.$containsi) {
                delete _query.filters.role;
            }
            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/users?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchUser(ctx, id, query = {}) {
            query.populate = ['picture', 'parkingLots', 'role'];
            return new Promise((resolve, reject) => {
                getUser(id, query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        updateUser(ctx, data) {
            console.log(data);
            let query = qs.stringify({
                populate: ['picture', 'parkingLots', 'role'],
            });
            return new Promise((resolve, reject) => {
                updateUser(data.id, data)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteUser(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/users/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteManyUsers(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/users/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                createUser(userData)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        /* addUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/apps/user/users', { user: userData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }, */
    },
};
